@font-face {
  font-family: 'segoeui' !important;
  src: url('../assets/fonts/SegoeUI.ttf') !important;
}

.rsc {
  background-image: url(/src/assets/images/bg_chatbot.jpeg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  display: block !important;
  max-width: 100% !important;
  width: 100% !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.avatarTop {
  width: 56px;
  height: 56px;
}

.linkContent {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05), 0 0px 0px rgba(0, 0, 0, 0.1);
  border: 0px !important;
}

.hgftJd {
  background-image: url(/src/assets/images/bg_chatbot.jpeg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100% !important;
  display: block !important;
  max-width: 100vw !important;
  width: 100vw !important;
  margin-left: auto !important;
  margin-right: auto;
  height: 100% !important;
  bottom: 0 !important;
  left: initial !important;
  right: 0 !important;
  top: initial !important;
}

.rsc-container {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto;
  background: transparent linear-gradient(180deg, #0C2A41 0%, #1E6397 100%) 0% 0% no-repeat padding-box !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0;
  bottom: 0 !important;
  left: initial !important;
  right: 0 !important;
  top: initial !important;
}

.rsc-cs {
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 0px !important;
}

.rsc-content {
  height: 80% !important;
}

.rsc-ts-bubble {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 16px !important;
  color: hsla(60, 1%, 23%, 1) !important;
  background-color: hsla(0, 0%, 100%, 0.7) !important;
  max-width: 80% !important;
}

@media (max-width: 569px) {
  .rsc-ts-bubble {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    color: hsla(60, 1%, 23%, 1) !important;
    background-color: hsla(0, 0%, 100%, 0.7) !important;
    margin: -8px 0px 10px 0px !important;
  }
}

@media (min-width: 570px) {
  .rsc-ts-bubble {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    color: hsla(60, 1%, 23%, 1) !important;
    background-color: hsla(0, 0%, 100%, 0.7) !important;
  }
}

.kUBvPo {
  color: hsla(0, 0%, 100%, 1) !important;
  background-color: rgb(25, 57, 240) !important;
}

.card-img-top {
  height: 140px !important;
  max-height: 140px !important;
}

.card-img-top-src {
  height: 140px !important;
  max-height: 140px !important;
}

.rsc-ts-image {
  weight: 16;
  height: 16;
  display: none;
  background-color: white;
}

@media (min-width: 570px) {
  .rsc-ts-image {
    display: block;
    height: 64px !important;
  }
}

.hDUXUW {
  position: absolute !important;
}

.rsc-footer {
  position: absolute;
  bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
@media (min-width: 570px) {
  .rsc-footer {
    position: absolute;
    bottom: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
}

@media (min-width: 570px) {
  .hide-img {
    display: none;
  }
}

.rsc-input {
  border: 10px !important;
  border-radius: 48px !important;
  -moz-border-radius: 48px !important;
  -webkit-border-radius: 48px !important;
  border: 0px solid #000000 !important;
  -webkit-box-shadow: 10px 10px 28px -8px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 10px 10px 28px -8px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 10px 10px 28px -8px rgba(0, 0, 0, 0.75) !important;
}

.multiselect {
  -webkit-animation: Lmuha 0.3s ease forwards;
  animation: Lmuha 0.3s ease forwards;
  cursor: pointer;
  margin: 2px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border: 2px solid rgb(25, 57, 240);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  font-size: 14px;
  padding: 12px;
  color: rgb(25, 57, 240);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: hsla(221, 85%, 95%, 0.5) !important;
}

.rsc-os-option-element {
  border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  -webkit-border-radius: 24px !important;
  border: 2px solid rgb(25, 57, 240) !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  color: rgb(25, 57, 240) !important;
  background: hsla(210, 85%, 95%, 0.5) !important;
}

.checkbox-select {
  cursor: pointer;
}
input[type='checkbox' i] {
  display: none;
}
input[type='checkbox'] + label {
  color: #000;
  font-style: italic;
}
input[type='checkbox']:checked {
  color: #0964aa;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.multi-select-options {
  display: block;
  text-align: center;
}

.jKLQUD {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  color: hsla(330, 100%, 41%, 1) !important;
  background: rgba(0, 0, 0, 0.01) !important;
}

.buttonChatboot {
  max-height: 44px;
  max-width: 44px;
  border-radius: 50%;
  border: 2px solid rgb(25, 57, 240);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  font-size: 16px !important;
  padding: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: hsla(0, 0%, 100%, 1) !important;
  background-color: rgb(25, 57, 240) !important;
}

.rsc-content {
  height: 80% !important;
}