.bg-primary {
    --cui-bg-opacity: 1 !important;
    background-color: #2C769C !important;
}

.btn-primary {
    --cui-btn-bg: #76C6EF !important;
    --cui-btn-border-color: #76C6EF !important;
    --cui-btn-color: rgba(255, 255, 255, 0.87) !important;
    --cui-btn-hover-bg: #2C769C !important;
    --cui-btn-hover-border-color: #2C769C !important;
    --cui-btn-hover-color: rgba(255, 255, 255, 0.87) !important;
    --cui-btn-active-bg: #2C769C !important;
    --cui-btn-active-border-color: #2C769C !important;
    --cui-btn-active-color: rgba(255, 255, 255, 0.87) !important;
    --cui-btn-disabled-bg: #cccccc !important;
    --cui-btn-disabled-border-color: #cccccc !important;
    --cui-btn-disabled-color: rgba(255, 255, 255, 0.87) !important;
    --cui-btn-shadow: rgba(50, 31, 219, 0.5) !important;
}

.sidebar {
    background: #2C769C;
}

.page-item.active .page-link {
    background-color: #2C769C;
    border-color: #2C769C;
}

.page-link {
    color: #c0c0c0;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    color: var(--cui-btn-disabled-color);
    pointer-events: none;
    background-color: var(--cui-btn-disabled-bg) !important;
    border-color: var(--cui-btn-disabled-border-color, transparent);
    opacity: 0.65;
}

@media (min-width: 992px) {
    .mktfilter {
        position: relative;
    }
}

@media (max-width: 991px) {
    .mktfilter {
        padding-top: 50px;
        position: relative;
    }
}


@media (min-width: 992px) {
    .ModalDetail {
        position: absolute;
        top: 20%;
        left: 25%;
        right: 25%;
        background-color: rgb(255, 255, 255);
        max-height: '600px';
        transform: 'translate(-50%, -50%)';
        border-radius: 0.75rem;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    }
  }

  @media (max-width: 991px) {
    .ModalDetail {
      position: absolute;
      top: 25%;
      left: 5%;
      right: 5%;
      bottom: 5%;
      background-color: rgb(255, 255, 255);
      max-height: '300px';
      overflow-y: auto;
      max-width: '100px';
      margin-right: '-50%';
      transform: 'translate(-50%, -50%)';
      padding-left: '5px';
      border-radius: 0.75rem;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    }
  }

.avatar {
    position: relative;
    display: inline-flex;
    /* align-items: center; */
    justify-content: center;
    vertical-align: middle;
    border-radius: 50em;
    transition: margin 0.15s;
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem;
}

.avatar-img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.75rem;
}

.sidebar-brand {
    background-color: #fff;
    border-right: 1px solid rgb(204, 204, 204);
}

.card {
    box-shadow: 0 1px 6px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 10%);
    border-radius: 0.75rem;
}

.card-reference {
    position: absolute;
    right: 0;
    top: 150px;
    padding: 1px 8px;
    text-align: center;
    background-color: #2C769C;
    height: 25px;
    margin: 0px;
    padding-top: 0px;
    color: rgb(255, 255, 255);
    border-top-left-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    box-shadow: 0 1px 6px rgb(0 0 0 / 15%), 0 1px 4px rgb(0 0 0 / 20%);
}

.mktplace-image {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
}